import * as Application from "expo-application";
import { Text, View, ScrollView, TouchableOpacity } from "react-native";
import colors from "../../tailwind/colors";

import Topbar from "../../components/Topbar";
import Background from "../../components/Background";
import { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

const getMundartSettingsFromStorage = async () => {
  const storedMundart = await AsyncStorage.getItem("isMundart");

  if (storedMundart === "true") {
    return true;
  } else {
    return false;
  }
};

export default function SettingsPage() {
  const [isMundart, setIsMundart] = useState(false);
  const [mode, setMode] = useState<string>();

  useEffect(() => {
    const getMundartSettings = async () => {
      await getMundartSettingsFromStorage().then((result) => {
        setIsMundart(result);
      });
      const mode = await AsyncStorage.getItem("mode");
      if (mode) {
        setMode(mode);
      }
    };
    getMundartSettings();
  }, []);

  const toggleMundart = async (value: boolean) => {
    setIsMundart(value);
    if (value) {
      AsyncStorage.setItem("isMundart", "true");
    } else {
      AsyncStorage.setItem("isMundart", "false");
    }
  };

  return (
    <View className="flex-1">
      <Topbar title={`Einstellungen ${Application.applicationName} `} />
      <Background colors={[colors.main[500], colors.main[400]]} />

      <ScrollView>
        {/** App Info Section */}
        <View className="mt-5 flex w-full flex-row ">
          <View className="flex-1 px-6">
            <Text
              adjustsFontSizeToFit
              numberOfLines={2}
              className=" text-lg font-semibold text-white"
            >
              Einstellungen
            </Text>
            <View>
              <Text className="mt-8 text-white">Tonspur:</Text>
              <View className="mt-4">
                <TouchableOpacity
                  className="flex flex-row items-center gap-3"
                  onPress={() => toggleMundart(!isMundart)}
                >
                  <View
                    className={`aspect-square h-8 rounded-full ${
                      isMundart
                        ? "bg-white"
                        : "border border-white bg-transparent"
                    }`}
                  />
                  <Text className="text-white">Mundart aktivieren</Text>
                </TouchableOpacity>
              </View>
            </View>
            <View>
              <Text className="mt-8 text-white">Abspielart:</Text>
              <View className="mt-4">
                <TouchableOpacity
                  className="flex flex-row items-center gap-3"
                  onPress={() => {
                    setMode("audio");
                    AsyncStorage.setItem("mode", "audio");
                  }}
                >
                  <View
                    className={`aspect-square h-8 rounded-full ${
                      mode === "audio"
                        ? "bg-white"
                        : "border border-white bg-transparent"
                    }`}
                  />
                  <Text className="text-white">Audio</Text>
                </TouchableOpacity>
              </View>
              <View className="mt-4">
                <TouchableOpacity
                  className="flex flex-row items-center gap-3"
                  onPress={() => {
                    setMode("video");
                    AsyncStorage.setItem("mode", "video");
                  }}
                >
                  <View
                    className={`aspect-square h-8 rounded-full ${
                      mode === "video"
                        ? "bg-white"
                        : "border border-white bg-transparent"
                    }`}
                  />
                  <Text className="text-white">Video</Text>
                </TouchableOpacity>
              </View>
              <View className="mt-4">
                <TouchableOpacity
                  className="flex flex-row items-center gap-3"
                  onPress={() => {
                    setMode("ar");
                    AsyncStorage.setItem("mode", "ar");
                  }}
                >
                  <View
                    className={`aspect-square h-8 rounded-full ${
                      mode === "ar"
                        ? "bg-white"
                        : "border border-white bg-transparent"
                    }`}
                  />
                  <Text className="text-white">Augmented Reality</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
}
