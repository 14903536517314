import React, { useEffect } from "react";

import { Text, View, TouchableOpacity, Image } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { createNativeStackNavigator } from "@react-navigation/native-stack";

export type TutorialStackParams = {
  page: { id: number };
  home: any;
};

const Stack = createNativeStackNavigator<TutorialStackParams>();
const TutorialLayout = () => {
  return (
    <View className="flex-1 bg-red-400">
      <Stack.Navigator
        screenOptions={{
          headerShown: false,

          contentStyle: {
            backgroundColor: "transparent",
          },
        }}
      >
        <Stack.Screen
          name="page"
          component={TutorialPage}
          initialParams={{ id: 0 }}
        />
      </Stack.Navigator>
    </View>
  );
};

export default TutorialLayout;

const TutorialSteps = [
  {
    subline: "Ich bin Ihr persönlicher Guide durch die App und das Museum",
    text: "Begleiten Sie mich auf einer fesselnden Reise durch die Geschichte von Stützerbach. Gemeinsam erkunden wir die beeindruckende Entwicklung des Dorfes zu einer international renommierten Glasindustrie. Lassen Sie mich Ihr Museumsführer sein und erleben Sie die einzigartige Geschichte unseres Ortes.",
  },
  {
    subline: "Scannen Sie die QR-Codes an den verschiedenen Stationen",
    text: "Erleben Sie die Geschichte von Stützerbach auf interaktive Weise! Nutzen Sie Ihr Handy, um an sechs verschiedenen Stationen den QR-Code zu scannen und vertiefende Einblicke in die einzelnen Ausstellungsbereiche zu erhalten.",
  },
  {
    subline: "Authentisches Erlebnis in lokaler Mundart",
    text: "Um das Ausstellungserlebnis so authentisch wie möglich zu gestalten, führe ich Sie in der charmanten Mundart Stützerbachs durch unser Museum! Erleben Sie die faszinierende Geschichte meines Dorfes in einer lokalen Erzählweise und tauchen Sie in die Welt der Glasherstellung ein.",
  },
];
type Props = NativeStackScreenProps<TutorialStackParams, "page">;
const TutorialPage = ({ route, navigation }: Props) => {
  const { id } = route.params;
  const [isMundart, setIsMundart] = React.useState(false);
  const step = TutorialSteps[id];
  if (!step) return null;
  const { subline, text } = step;

  const handleNext = () => {
    if (id < TutorialSteps.length - 1) {
      navigation.navigate("page", { id: id + 1 });
    } else {
      AsyncStorage.setItem("introDone", "true");
      navigation.navigate("home");
    }
  };

  useEffect(() => {
    const getSavedStatus = async () => {
      const isMundart = await AsyncStorage.getItem("isMundart");
      if (isMundart === "true") {
        setIsMundart(true);
      } else {
        setIsMundart(false);
      }
    };

    getSavedStatus();
  }, []);

  return (
    <>
      <View className="relative flex h-full bg-transparent">
        <Image
          source={require("../assets/modal_bg.jpg")}
          className=" absolute h-full w-full bg-main opacity-100"
          resizeMode="cover"
        />
        <View className=" top-16 px-8">
          <Image
            source={require("../assets/bubble_top.png")}
            className="h-12 w-full"
            style={{
              resizeMode: "stretch",
            }}
          />
          <View className="bg-main px-4">
            <View>
              <Text className="text-xl text-white">{subline}</Text>
              <View className="h-3" />
              <Text className="leading-[20px] text-white">{text}</Text>
              {id === TutorialSteps.length - 1 && (
                <View className="mt-4">
                  <TouchableOpacity
                    className="flex flex-row items-center gap-3"
                    onPress={() => {
                      if (isMundart) {
                        setIsMundart(false);
                        AsyncStorage.setItem("isMundart", "false");
                      } else {
                        setIsMundart(true);
                        AsyncStorage.setItem("isMundart", "true");
                      }
                    }}
                  >
                    <View
                      className={`aspect-square h-8 rounded-full ${
                        isMundart
                          ? "bg-white"
                          : "border border-white bg-transparent"
                      }`}
                    />
                    <Text className="text-white">Mundart aktivieren</Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>

            <View className="h-16 pt-8"></View>
          </View>

          <View className="relative">
            <Image
              source={require("../assets/bubble_bottom.png")}
              className="h-32 w-full"
              resizeMode="stretch"
            />
            <View className="absolute left-0 right-0 mx-auto self-end">
              <View className="flex-row items-center gap-3 self-center pb-4 pt-8">
                {TutorialSteps.map((_, i) => {
                  return (
                    <View
                      key={i}
                      className={`aspect-square h-2 rounded-full bg-white ${
                        id === i ? "opacity-100" : "opacity-40"
                      }`}
                    />
                  );
                })}
              </View>
            </View>
          </View>
        </View>
        <TouchableOpacity
          className="absolute bottom-16 z-50 self-center rounded-full bg-accent px-12 py-4"
          onPress={handleNext}
        >
          <Text className="font-semibold text-white">Weiter</Text>
        </TouchableOpacity>
        <Image
          source={require("../assets/hans.png")}
          className="pointer-events-none absolute -bottom-0 -right-0 -z-0 h-[400px]"
          style={{ resizeMode: "contain" }}
        />
      </View>
    </>
  );
};
