module.exports = {
  main: {
    DEFAULT: "#719751",
    300: "#B4C897",
    400: "#719751",
    500: "#446428",
    600: "#576749",
  },

  alternative: "#446428",
  altSeconday: "#446428",
  altDark: "#446428",
  secondary: "#B4C897",
  accent: "#FBBC43",
  neutral: "#403F3F",
  dark: "#576749",
};
