import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: 2 } },
});
import "./styles.css";
import { NavigationContainer } from "@react-navigation/native";
import RootLayout from "./pages/_layout";
import { StatusBar } from "expo-status-bar";
import { Platform, Text } from "react-native";
if (Platform.OS === "web") {
  // @ts-ignore
  global._frameTimestamp = null;
}
import { NativeWindStyleSheet } from "nativewind";
import Deeplink from "./components/Deeplink";
import React from "react";

NativeWindStyleSheet.setOutput({ web: "native", default: "native" });
const linking = {
  prefixes: [
    /* your linking prefixes */
  ],
  config: {},
};

export default function App() {
  const [navIsReady, setNavIsReady] = React.useState(false);

  return (
    <>
      <script
        type="module"
        src="https://ajax.googleapis.com/ajax/libs/model-viewer/3.0.1/model-viewer.min.js"
      ></script>
      <QueryClientProvider client={queryClient}>
        <StatusBar style="light" />
        <NavigationContainer
          documentTitle={{
            formatter: () => `Wald-Glas-Stützerbach`,
          }}
          fallback={<Text>Loading...</Text>}
          onReady={() => {
            setNavIsReady(true);
          }}
        >
          <RootLayout />
          {navIsReady && <Deeplink />}
        </NavigationContainer>
      </QueryClientProvider>
    </>
  );
}
