import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import ImpressumPage from "./impressum";
import AboutPage from "./about";
import SettingsPage from "./settings";

export type AboutStackParamList = {
  impressum: undefined;
  settings: undefined;
  info: undefined;
};

const Stack = createNativeStackNavigator<AboutStackParamList>();
const AboutLayout = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="info" component={AboutPage} />
      <Stack.Screen name="settings" component={SettingsPage} />
      <Stack.Screen name="impressum" component={ImpressumPage} />
    </Stack.Navigator>
  );
};

export default AboutLayout;
