import * as Application from "expo-application";
import {
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  Platform,
  Linking,
} from "react-native";
import colors from "../../tailwind/colors";

import Topbar from "../../components/Topbar";
import * as Updates from "expo-updates";
import Background from "../../components/Background";

export default function AboutPage() {
  return (
    <View className="flex-1">
      <Background colors={[colors.main[500], colors.main[400]]} />
      <Topbar title={`Über ${Application.applicationName} `} />

      <ScrollView endFillColor="#000" overScrollMode="never">
        {/** App Info Section */}
        <View className="mt-5 flex w-full flex-row ">
          <View className=" px-6">
            <Image
              source={require("../../assets/icon.png")}
              className="h-20 w-20 rounded-md"
            />
          </View>
          <View className="flex-1 px-6">
            <Text
              adjustsFontSizeToFit
              numberOfLines={2}
              className=" text-lg font-semibold text-white"
            >
              {Application.applicationName}
            </Text>
            <Text className="mb-1 text-base text-white">
              Version {Application.nativeApplicationVersion}
            </Text>
            <Text className="mb-4 text-sm text-white">
              (c) 2023 {Application.applicationName} {"\n"}Alle Rechte
              vorbehalten
            </Text>
          </View>
        </View>

        <View className="flex-col  border-[#fff8] px-6 pb-8 pt-8">
          <Text className="mb-3 text-sm text-white">🙏 Herausgegeben von:</Text>

          <View className="mb-8 h-32 w-full overflow-hidden rounded-xl bg-white py-4">
            <Image
              source={require("../../assets/logo_heimatverein.jpg")}
              className="h-full w-full"
              resizeMode="contain"
            />
          </View>

          <Text className="mb-3 text-sm text-white">
            🙏 Das Projekt „Steigerung Erlebniswert Glas- und Heimatmuseum durch
            die Einbindung moderner Medien“ wird gefördert durch das Thüringer
            Ministerium für Umwelt, Energie und Naturschutz:
          </Text>
          <View className="mb-4  w-full overflow-hidden rounded-xl bg-white px-4  py-6">
            <View className="flex-1 bg-white">
              <View className="flex-1 items-center justify-center">
                <TouchableOpacity
                  className="w-full"
                  onPress={() =>
                    Linking.openURL("https://umwelt.thueringen.de/")
                  }
                >
                  <Image
                    source={require("../../assets/logos/umwelt_ministerium.png")}
                    resizeMode="contain"
                    className="h-32 w-full "
                  />
                </TouchableOpacity>

                <View className="mb-8 h-1 w-full border-t border-t-gray-200 "></View>

                <TouchableOpacity
                  className="w-full"
                  onPress={() =>
                    Linking.openURL(
                      "https://www.biosphaerenreservat-thueringerwald.de/"
                    )
                  }
                >
                  <Image
                    source={require("../../assets/logos/brtw.png")}
                    resizeMode="contain"
                    className="h-16 w-full "
                  />
                </TouchableOpacity>

                <View className="my-4 h-24 flex-row">
                  <TouchableOpacity
                    className="h-full flex-1 items-center justify-center px-4"
                    onPress={() => Linking.openURL("https://www.ilmenau.de/")}
                  >
                    <Image
                      source={require("../../assets/logos/stadt_ilmenau.png")}
                      resizeMode="contain"
                      className="h-full flex-1 items-center justify-center px-4"
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    className="h-full flex-1 items-center justify-center px-4"
                    onPress={() =>
                      Linking.openURL("https://www.stuetzerbach.de/")
                    }
                  >
                    <Image
                      resizeMode="contain"
                      className="h-full flex-1 items-center justify-center px-4 "
                      source={require("../../assets/logos/gemeinde_stuetzerbach.png")}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
        {/** App Navigation Section with Impressum Datenschutz */}
        <View className="mt-16 flex w-full flex-col  border-b border-t border-[#fff8] px-6 pb-8 pt-12">
          <TouchableOpacity
            onPress={() => Linking.openURL("https://hgv-stuetzerbach.de/")}
          >
            <Text className="mb-5 text-base font-semibold text-white">
              Besuche unsere Webseite
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              Linking.openURL("https://app.wald-glas-stuetzerbach.de/impressum")
            }
          >
            <Text className="mb-5 text-base font-semibold text-white">
              Impressum
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() =>
              Linking.openURL(
                "https://app.wald-glas-stuetzerbach.de/datenschutz"
              )
            }
          >
            <Text className="mb-5 text-base font-semibold text-white">
              Datenschutz
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => Linking.openURL("mailto:digital@cognitio.de")}
          >
            <Text className="mb-5 text-base font-semibold text-white">
              Kontakt ✉️ digital@cognitio.de
            </Text>
          </TouchableOpacity>
        </View>

        {/** App Debugsection */}
        <View className="flex w-full flex-col px-6 pb-8 pt-8">
          <Text className=" text-sm text-white">Debug: {Updates.updateId}</Text>
          <Text className=" text-sm text-white">
            CET/{Updates.createdAt?.getTime()}
          </Text>
          <Text className=" text-sm text-white">
            V{Application.nativeApplicationVersion} {Platform.OS}
          </Text>
        </View>
      </ScrollView>
    </View>
  );
}
