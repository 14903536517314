import React from "react";
import { Image, Text, View } from "react-native";

export default function Splashscreen() {
  return (
    <View className="flex-1 bg-white p-8 ">
      <View className="flex-1 items-center justify-center">
        <Text className="text-center text-xs leading-5">
          Das Projekt „Steigerung Erlebniswert Glas- und Heimatmuseum durch die
          Einbindung moderner Medien“ wird gefördert durch das Thüringer
          Ministerium für Umwelt, Energie und Naturschutz
        </Text>
        <Image
          source={require("../assets/logos/umwelt_ministerium.png")}
          resizeMode="contain"
          className="w-full"
        />
        <View className="my-4 h-[1] w-full bg-gray-300"></View>

        <Image
          source={require("../assets/logos/brtw.png")}
          resizeMode="contain"
          className="w-full"
        />

        <View className="my-4 h-24 flex-row">
          <Image
            source={require("../assets/logos/stadt_ilmenau.png")}
            resizeMode="contain"
            className="h-full flex-1 items-center justify-center px-4"
          />

          <Image
            resizeMode="contain"
            className="h-full flex-1 items-center justify-center px-4 "
            source={require("../assets/logos/gemeinde_stuetzerbach.png")}
          />
        </View>
        <Image
          source={require("../assets/logos/heimat_museum.png")}
          resizeMode="contain"
          className="h-24 self-center"
        />
      </View>
    </View>
  );
}
