import React, { useEffect } from "react";
import {
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { Feather, Ionicons } from "@expo/vector-icons";
import Logo from "../../assets/logo.svg";
import LogoBild from "../../assets/logo_bildmarke.svg";
import DekoBottom from "../../assets/deko-bottom.svg";
import DekoTop from "../../assets/deko_top.svg";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { AnimatePresence, MotiView } from "moti";
import colors from "../../tailwind/colors";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useNavigation } from "@react-navigation/core";
import { RootStackParamList } from "../_layout";
import { applicationName } from "expo-application";
import { useIsFocused } from "@react-navigation/native";
const StartTab = () => {
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);
  const [isMundart, setIsMundart] = React.useState(false);
  const navigation =
    useNavigation<NativeStackNavigationProp<RootStackParamList>>();
  const { top } = useSafeAreaInsets();
  const isFocused = useIsFocused();
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    const getSavedStatus = async () => {
      const isMundart = await AsyncStorage.getItem("isMundart");
      const isIntroDone = await AsyncStorage.getItem("introDone");
      if (isMundart === "true") {
        setIsMundart(true);
      } else {
        setIsMundart(false);
      }
      if (!isIntroDone) {
        navigation.navigate("tutorial");
      }
    };

    getSavedStatus();
  }, [isFocused]);

  return (
    <>
      <View className="relative flex-1 items-center justify-center">
        <DekoTop
          style={{ top: -40, position: "absolute", zIndex: -1 }}
          preserveAspectRatio="xMidYMid slice"
          width={width}
        />

        <View
          className="absolute right-4 z-50 opacity-70"
          style={{ top: Math.max(top + 8, 32) }}
        >
          <TouchableOpacity onPress={() => setIsMenuVisible(true)}>
            <Feather name="info" size={32} color="white" />
          </TouchableOpacity>
        </View>

        <View className="flex w-screen items-center justify-center ">
          <View
            className="items-center"
            style={{
              width: width * 0.6,
            }}
          >
            <LogoBild />
            <View className="p-1"></View>
            <Logo preserveAspectRatio="xMidYMid cover" />
          </View>
          <View className="px-8  pt-4">
            <Text className="text-center leading-5 text-white">
              Scannen Sie die QR-Codes an den verschiedenen Stationen der
              Ausstellung, um die Geschichte unseres Ortes zu erleben.
            </Text>
          </View>
          <View className="mt-4 pb-24">
            <TouchableOpacity
              className="flex flex-row items-center gap-3"
              onPress={() => {
                if (isMundart) {
                  setIsMundart(false);
                  AsyncStorage.setItem("isMundart", "false");
                } else {
                  setIsMundart(true);
                  AsyncStorage.setItem("isMundart", "true");
                }
              }}
            >
              <View
                className={`aspect-square h-8 rounded-full ${
                  isMundart ? "bg-white" : "border border-white bg-transparent"
                }`}
              />
              <Text className="text-white">Mundart aktivieren</Text>
            </TouchableOpacity>
          </View>
        </View>

        <DekoBottom
          preserveAspectRatio="xMidYMid slice"
          width={width}
          height={200}
          style={{
            top: height * 0.8,
            position: "absolute",
            zIndex: -1,
          }}
        />

        <AnimatePresence>
          {isMenuVisible ? (
            <TouchableOpacity
              activeOpacity={1}
              className="absolute bottom-0 h-screen w-full items-center justify-center bg-transparent"
              onPress={() => setIsMenuVisible(false)}
            >
              <MotiView
                from={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 150 }}
                exit={{ opacity: 0 }}
                style={{ top: Math.max(top + 8, 32) }}
                className="absolute right-4 items-center justify-center rounded-md bg-white shadow-sm shadow-[#0004]"
              >
                <MotiView
                  from={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ delay: isMenuVisible ? 0 : 100 }}
                  className="gap-4"
                >
                  <TouchableOpacity
                    className="flex-row items-center  pl-6 pr-8"
                    onPress={() => {
                      navigation.navigate("about");
                      setIsMenuVisible(false);
                    }}
                  >
                    <Ionicons
                      name="information-sharp"
                      size={24}
                      color={colors.main[400]}
                    />
                    <Text className="ml-2 mt-1 text-sm">
                      Über {applicationName}
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    className="flex-row items-center  pl-6 pr-8"
                    onPress={() => {
                      navigation.navigate("about", { screen: "settings" });
                      setIsMenuVisible(false);
                    }}
                  >
                    <Ionicons
                      name="settings-sharp"
                      size={24}
                      color={colors.main[400]}
                    />
                    <Text className="ml-2 mt-1 text-sm">Einstellungen</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    className="flex-row items-center  pl-6 pr-8"
                    onPress={() => {
                      navigation.navigate("tutorial");
                      setIsMenuVisible(false);
                    }}
                  >
                    <Ionicons
                      name="help-circle"
                      size={24}
                      color={colors.main[400]}
                    />
                    <Text className="ml-2 mt-1 text-sm">Hilfe anzeigen</Text>
                  </TouchableOpacity>
                </MotiView>
              </MotiView>
            </TouchableOpacity>
          ) : null}
        </AnimatePresence>
      </View>
    </>
  );
};

export default StartTab;
