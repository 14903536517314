import { LinearGradient } from "expo-linear-gradient";
import React, { useEffect, useState } from "react";
import {
  ColorValue,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import colors from "../tailwind/colors";
import PlayIcon from "../assets/icons/play.svg";
import PauseIcon from "../assets/icons/pause.svg";
import ForwardIcon from "../assets/icons/forward-10.svg";
import RewindIcon from "../assets/icons/rewind-10.svg";
import NextIcon from "../assets/icons/skip-forward.svg";
import PrevIcon from "../assets/icons/play-skip-back.svg";
import Slider from "@react-native-community/slider";
import AsyncStorage from "@react-native-async-storage/async-storage";
import type { Sound } from "expo-av/build/Audio";
import { formatMilliseconds } from "./formatMilliseconds";
import { Audio } from "expo-av";
import { useIsFocused } from "@react-navigation/native";
const unloadSound = async (sound: Sound) => {
  await sound.unloadAsync();
};

let soundFile: Sound;

function Audioplayer({
  id,
  soundSrc,
  mini,
  color,
  soundMetadata,
}: {
  id: string;
  mini?: boolean;
  string?: boolean;
  color?: ColorValue;
  soundSrc: any;
  soundMetadata?: {
    position?: number;
  };
}) {
  const { width } = useWindowDimensions();
  const isFocused = useIsFocused();
  const [sound, setSound] = useState<Sound>();
  const [soundMeta, setSoundMeta] = useState({
    duration: 2129,
    position: 0.1,
    isPlaying: false,
  });

  const togglePlaystate = async () => {
    if (!sound) return;

    if (soundMeta.isPlaying) {
      await sound.pauseAsync();
      return;
    }
    await sound.playAsync();
  };

  useEffect(() => {
    if (!isFocused) {
      unloadSound(soundFile);
    }
  }, [isFocused]);

  useEffect(() => {
    if (!soundSrc) return;

    const handleSoundUpdate = (e: any) => {
      const { durationMillis, isPlaying, positionMillis } = e;
      setSoundMeta({
        duration: durationMillis,
        isPlaying: isPlaying,
        position: positionMillis,
      });
      const val = JSON.stringify({
        position: positionMillis,
        isDone: false,
      });
      if (isPlaying) {
        AsyncStorage.setItem(`${id}`, val.toString());
      }
    };
    soundFile = new Audio.Sound();

    const createSound = async () => {
      try {
        await Audio.setAudioModeAsync({
          playsInSilentModeIOS: true,
        });

        const initialStatus = soundMetadata;

        await soundFile.loadAsync(soundSrc, {
          positionMillis: initialStatus?.position,
        });
        soundFile.setOnPlaybackStatusUpdate(handleSoundUpdate);

        setSound(soundFile);
      } catch (error) {
        console.warn(error);
      }
    };
    createSound();
    return () => {
      unloadSound(soundFile);
    };
  }, [soundSrc]);

  if (mini) {
    return (
      <View className="relative flex-1 flex-row items-center justify-between rounded-full bg-[#fffffffc] px-4">
        {/** controls */}
        <TouchableOpacity onPress={togglePlaystate}>
          {soundMeta.isPlaying ? (
            <PauseIcon
              height={20}
              width={20}
              fill={color || colors.main[400]}
            />
          ) : (
            <PlayIcon
              style={{ marginLeft: 2 }}
              height={18}
              width={18}
              fill={color || colors.main[400]}
            />
          )}
        </TouchableOpacity>

        <Slider
          style={{ width: 150, height: 24 }}
          minimumValue={0}
          maximumValue={soundMeta.duration}
          onSlidingComplete={(position) => {
            sound?.setPositionAsync(position);
          }}
          thumbTintColor="#fff0"
          value={soundMeta.position}
          minimumTrackTintColor={colors.accent}
          maximumTrackTintColor="#0004"
        />

        <View className="flex-row justify-between">
          <Text className="text-xs text-gray-400 opacity-70">
            {formatMilliseconds(soundMeta.position)} /{" "}
            {formatMilliseconds(soundMeta.duration)}
          </Text>
        </View>
      </View>
    );
  }
  return (
    <View className=" flex-col overflow-hidden rounded-[40px] bg-dark ">
      <LinearGradient
        colors={[colors.main[500], colors.main[400]]}
        style={{ flex: 1 }}
      >
        <View className="px-10 pt-5">
          <View className="flex-row items-center justify-between">
            {/** controls */}
            <TouchableOpacity
              onPress={() =>
                sound?.setPositionAsync(soundMeta.position - 10000)
              }
            >
              <RewindIcon
                style={{ opacity: 0.8 }}
                height={24}
                width={24}
                fill="white"
              />
            </TouchableOpacity>
            {/* <TouchableOpacity>
              <View className="h-10 w-10 items-center justify-center rounded-full bg-[#0002]">
                <PrevIcon height={16} width={16} fill="white" />
              </View>
            </TouchableOpacity> */}
            <TouchableOpacity
              className="shadow-sm shadow-[#0005]"
              onPress={togglePlaystate}
            >
              <View className=" h-16 w-16 overflow-hidden rounded-full">
                <LinearGradient
                  colors={[colors.accent, colors.accent]}
                  style={{
                    height: "100%",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {soundMeta.isPlaying ? (
                    <PauseIcon height={20} width={20} fill="white" />
                  ) : (
                    <PlayIcon
                      style={{ marginLeft: 2 }}
                      height={18}
                      width={18}
                      fill="white"
                    />
                  )}
                </LinearGradient>
              </View>
            </TouchableOpacity>
            {/* <TouchableOpacity>
              <View className="h-10 w-10 items-center justify-center rounded-full bg-[#0002]">
                <NextIcon height={16} width={16} fill="white" />
              </View>
            </TouchableOpacity> */}
            <TouchableOpacity
              onPress={() =>
                sound?.setPositionAsync(soundMeta.position + 10000)
              }
            >
              <ForwardIcon
                height={24}
                width={24}
                fill="white"
                style={{ opacity: 0.75 }}
              />
            </TouchableOpacity>
          </View>
          {/** Statusbar */}
          <View className="mt-3">
            <Slider
              style={{ width: width - 105, height: 24 }}
              minimumValue={0}
              maximumValue={soundMeta.duration}
              onSlidingComplete={(position) => {
                sound?.setPositionAsync(position);
              }}
              thumbTintColor={colors.main[500]}
              value={soundMeta.position}
              minimumTrackTintColor={colors.accent}
              maximumTrackTintColor="#0004"
            />

            <View className="flex-row justify-between pb-4">
              <Text className="text-xs text-white opacity-70">
                {formatMilliseconds(soundMeta.position)}
              </Text>
              <Text className="text-xs text-white opacity-70">
                {formatMilliseconds(soundMeta.duration)}
              </Text>
            </View>
          </View>
        </View>
      </LinearGradient>
    </View>
  );
}

export default Audioplayer;
