import { LinkingOptions, useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect } from "react";
import { Linking, Platform, View } from "react-native";
import { RootStackParamList } from "../pages/_layout";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
function Deeplink() {
  const navigation =
    useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  Linking.getInitialURL().then((e) => {
    if (e) {
      handleDeeplink({ url: e });
    }
  });

  const handleDeeplink = useCallback(
    ({ url }: { url: string }) => {
      if (!url) {
        return;
      }

      const id = url.split("?id=")[1];
      if (!id) return;
      if (navigation) {
        if (Platform.OS !== "web") {
          navigation.navigate("map", { id });
        } else {
          navigation.navigate("station", { id });
        }
      }
    },
    [navigation]
  );

  useEffect(() => {
    const LinkingListener = Linking.addEventListener("url", handleDeeplink);

    return () => {
      LinkingListener.remove();
    };
  }, [handleDeeplink, navigation]);
  return <View />;
}

export default Deeplink;
