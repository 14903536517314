import React from "react";

import { Text, View, ScrollView } from "react-native";
import colors from "../../tailwind/colors";
import { useQuery, useQueryClient } from "react-query";
import Html from "../../components/Html";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { AboutStackParamList } from "./_layout";
import Topbar from "../../components/Topbar";
import Background from "../../components/Background";

type Props = NativeStackScreenProps<AboutStackParamList, "impressum">;

const ImpressumPage: React.FC<Props> = ({ route, navigation }) => {
  const queryClient = useQueryClient();
  const { data: station, isLoading } = useQuery("projekt", () => ({
    title: "Weser erfahren - Natur erleben",
    subline: "asd",
    text: "asd",
    image: null,
  }));

  if (isLoading || !station) {
    //todo Add Loader
    return (
      <View className="flex-1 bg-main">
        <Background colors={[colors.main[500], colors.main[400]]} />
      </View>
    );
  }

  return (
    <View className="flex-1">
      <Background colors={[colors.main[500], colors.main[400]]} />
      <Topbar />

      <ScrollView endFillColor="#000" overScrollMode="never">
        <View className="mt-5 flex w-full flex-col px-6">
          <View className="flex-col">
            <Text
              adjustsFontSizeToFit
              numberOfLines={2}
              className="mb-2 text-3xl text-white"
            >
              Impressum
            </Text>
            <Text className="mb-4 text-lg text-white">{station.subline}</Text>

            <Html html={station.text} />
          </View>
        </View>
        <View className="flex w-full flex-col items-center pt-8">
          {/* <LinearGradient
            colors={["transparent", colors.dark]}
            style={{ width: "100%" }}
          > */}

          {/* </LinearGradient> */}
        </View>
      </ScrollView>
    </View>
  );
};

export default ImpressumPage;
