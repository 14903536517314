import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { Dimensions } from "react-native";
const { height } = Dimensions.get("screen");
const Background = ({ colors }: { colors: string[] }) => {
  return (
    <LinearGradient
      className="absolute h-screen"
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        zIndex: 0,
        height,
      }}
      colors={colors}
    />
  );
};

export default Background;
