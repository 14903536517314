import { Platform, View } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import colors from "../../tailwind/colors";
import QRIcon from "../../assets/qr-code.svg";
import StartIcon from "../../assets/home.svg";
import PeopleIcon from "../../assets/icons/people.svg";
import LibraryIcon from "../../assets/icons/library-building.svg";

import MyTabBar from "../../components/BottomTabs";
import StartTab from "./start";
import ScannerTab from "./scanner";
import Background from "../../components/Background";
import VereinPage from "./verein";
import MuseumPage from "./museum";

export type HomeTabsParamList = {
  start: undefined;
  touren: undefined;
  ar: undefined;
  spacer: undefined;
  verein: undefined;
  museum: undefined;
  map: {
    id?: string;
  };
};

const Tabs = createBottomTabNavigator<HomeTabsParamList>();
const HomeLayout = () => {
  return (
    <>
      <Background colors={[colors.main[400], colors.main[400]]} />

      <Tabs.Navigator
        tabBar={(props) => {
          return <MyTabBar {...props} />;
        }}
        sceneContainerStyle={{
          backgroundColor: "transparent",
        }}
        screenOptions={{
          headerShown: false,
        }}
      >
        <Tabs.Screen
          name="start"
          options={{
            tabBarIcon: (focused) => (
              <StartIcon
                strokeWidth={1.75}
                stroke={focused ? "#fff" : "#fff6"}
              />
            ),
            tabBarLabel: "Home",
          }}
          component={StartTab}
        />
        <Tabs.Screen
          name="museum"
          options={{
            tabBarLabel: "Das Museum",
            tabBarIcon: (focused) => (
              <LibraryIcon fill={focused ? "#fff" : "#fff9"} />
            ),
          }}
          component={MuseumPage}
        />
        <Tabs.Screen
          name="verein"
          options={{
            tabBarLabel: "Der Verein",
            tabBarIcon: (focused) => (
              <PeopleIcon fill={focused ? "#fff" : "#fff9"} />
            ),
          }}
          component={VereinPage}
        />

        {Platform.OS !== "web" && (
          <Tabs.Screen
            name="map"
            options={{
              tabBarLabel: () => null,
              tabBarIcon: (focused) => (
                <View className=" aspect-square h-full  items-center justify-center rounded-full bg-accent">
                  <QRIcon
                    strokeWidth={1.75}
                    width={24}
                    height={24}
                    color={focused ? "#fff" : "#fff9"}
                  />
                </View>
              ),
            }}
            component={ScannerTab}
          />
        )}
      </Tabs.Navigator>
    </>
  );
};

export default HomeLayout;
