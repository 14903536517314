import { Text, View, ScrollView } from "react-native";
import colors from "../../tailwind/colors";
import { useQuery } from "react-query";
import Html from "../../components/Html";
import Background from "../../components/Background";
import { PageData } from "../../utils/types";

const fetchPage = async (): Promise<PageData> => {
  const apiUrl =
    process.env.EXPO_PUBLIC_API_URL ||
    "https://cms.wald-glas-stuetzerbach.de/api";
  const apiToken =
    process.env.EXPO_PUBLIC_API_TOKEN ||
    "account-b92f17d205bc8317997d5a8db9f17d";

  const data = await fetch(
    `${apiUrl}/collections/get/Page/?filter[title_slug]=ueber-den-verein&token=${apiToken}&simple=1`
  ).then((res) => res.json());
  return data[0];
};

export default function VereinPage() {
  const { data: page, isLoading } = useQuery("projekt", fetchPage);

  if (isLoading) {
    //todo Add Loader
    return (
      <View className="flex-1 bg-main">
        <Background colors={[colors.main[500], colors.main[400]]} />
      </View>
    );
  }
  if (!page) {
    return (
      <View className="flex-1 bg-main">
        <Background colors={[colors.main[500], colors.main[400]]} />
      </View>
    );
  }

  return (
    <View className="flex-1 ">
      <Background colors={[colors.main[500], colors.main[400]]} />
      <View className="mb-3 h-10" />

      <ScrollView>
        <View className="mt-5 flex w-full flex-col px-6">
          <View className="flex-col">
            <Text
              adjustsFontSizeToFit
              numberOfLines={2}
              className="mb-2 text-3xl text-white"
            >
              {page.title}
            </Text>
            <Text className="mb-4 text-lg text-white">{page.subline}</Text>

            {page.block.map((block, index) => (
              <Html key={index} html={block.value.text} />
            ))}
          </View>
        </View>
        <View className="h-24" />
      </ScrollView>
    </View>
  );
}
