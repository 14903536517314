import { useEffect, useRef, useState } from "react";
import { BarCodeScanner } from "expo-barcode-scanner";
import * as Device from "expo-device";
import {
  ActivityIndicator,
  Alert,
  Linking,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import * as FileSystem from "expo-file-system";

import BarcodeScannerWrapper from "../../components/BarcodeScanner";
import FileViewer from "react-native-file-viewer";

import { useIsFocused } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ResizeMode, Video } from "expo-av";
import AudioScreen from "../../components/AudioScreen";

export const baseUrl =
  process.env.EXPO_PUBLIC_BASE_URL || "https://cms.wald-glas-stuetzerbach.de";

export const apiUrl =
  process.env.EXPO_PUBLIC_API_URL ||
  "https://cms.wald-glas-stuetzerbach.de/api";
export const apiToken =
  process.env.EXPO_PUBLIC_API_TOKEN || "account-b92f17d205bc8317997d5a8db9f17d";
type Mode = "ar" | "audio" | "video";
const ScannerTab = ({ navigation, route }: { navigation: any; route: any }) => {
  const isFocused = useIsFocused();
  const [mode, setMode] = useState<Mode>();
  const [hasPermission, setHasPermission] = useState<boolean>();
  const [scanned, setScanned] = useState(false);
  const [showVideo, setShowVideo] = useState<boolean | string>(false);
  const [canUseAr, setIsARSupportedOnDevice] = useState(true); //
  const [showAudio, setShowAudio] = useState<{
    src: string;
    title: string;
    subline: string;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const videoRef = useRef<Video>(null);
  useEffect(() => {
    const { osVersion, modelName } = Device;

    if (
      parseInt(osVersion!) > 12 &&
      modelName !== "iPhone 6" &&
      modelName !== "iPhone 5" &&
      modelName !== "iPhone 6S" &&
      modelName !== "iPhone 6S Plus" &&
      modelName !== "iPhone 5S" &&
      modelName !== "iPhone 4S" &&
      modelName !== "iPhone 4"
    ) {
      setIsARSupportedOnDevice(true);
    } else {
      setIsARSupportedOnDevice(false);
    }

    if (route.params?.id) {
      startAR(route.params.id);
    }
  }, [route]);

  useEffect(() => {
    const getBarCodeScannerPermissions = async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === "granted");
    };

    getBarCodeScannerPermissions();
  }, [hasPermission]);

  useEffect(() => {
    if (isFocused) {
      AsyncStorage.getItem("mode").then((mode) => {
        if (mode) {
          setMode(mode as Mode);
        }
      });

      setLoading(false);
      setShowVideo(false);
      setShowAudio(null);
      setScanned(false);
      setShowCamera(true);
      // ScreenOrientation.lockAsync(
      //   ScreenOrientation.OrientationLock.PORTRAIT_UP
      // );
    } else {
      navigation.setParams({ tabBar: true });
    }
  }, [isFocused]);

  useEffect(() => {
    if (navigation) {
      if (showAudio) {
        navigation.setParams({ tabBar: false });
      } else {
        setShowCamera(false);
        setShowCamera(true);
        setScanned(false);
        setLoading(false);
        navigation.setParams({ id: null, tabBar: true });
      }
    }
  }, [showAudio, showVideo, navigation]);

  // useEffect(() => {
  //   if (!showVideo) {
  //     ScreenOrientation.lockAsync(
  //       ScreenOrientation.OrientationLock.PORTRAIT_UP
  //     );
  //   } else {
  //     ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE);
  //   }
  // }, [showVideo]);

  const startAR = async (id: string) => {
    const isMundart = await AsyncStorage.getItem("isMundart");
    const url = `${apiUrl}/collections/get/stationen/?filter[title_slug]=${id}&token=${apiToken}&simple=1&date=${Date.now()}`;
    const data = await fetch(url).then((res) => res.json());

    if (!data) {
      Alert.alert("Ungültiger QR-Code", "Bitte versuche es erneut.", [
        { text: "OK", onPress: () => setScanned(false) },
      ]);
      return;
    }

    if (data[0].video) {
      setShowVideo(data[0].video);
      return;
    }

    if (mode === "video") {
      const video =
        isMundart === "true"
          ? `${baseUrl}/${data[0].arvideo_mundart}`
          : `${baseUrl}/${data[0].arvideo}`;
      setShowVideo(video);
      return;
    }

    if (!canUseAr || mode === "audio") {
      const sound =
        isMundart === "true"
          ? `${baseUrl}/${data[0].android.audio_mundart}`
          : `${baseUrl}/${data[0].android.audio}`;
      setShowAudio({
        src: sound,
        title: data[0].title,
        subline: data[0].subline,
      });
      return;
    }

    const src =
      isMundart === "true"
        ? `${baseUrl}/${data[0].ios.model_mundart}`
        : `${baseUrl}/${data[0].ios.model}`;

    const fileName = src.split("/").pop();
    const localFile = `${FileSystem.documentDirectory}/${fileName}`;
    const tmp = await FileSystem.getInfoAsync(localFile);

    if (tmp.exists) {
      FileViewer.open(localFile);
      setTimeout(() => {
        setScanned(false);
        setLoading(false);
        navigation.setParams({ id: null });
      }, 2000);
      return;
    }
    const download = FileSystem.createDownloadResumable(src, localFile);

    try {
      const localModel = (await download.downloadAsync()) as { uri: string };
      await FileViewer.open(localModel?.uri);
      setTimeout(() => {
        setScanned(false);
        setLoading(false);
      }, 2000);
    } catch (e) {
      console.error(e);
    }

    setScanned(false);
  };

  const handleBarCodeScanned = ({ data }: { type: string; data: string }) => {
    setScanned(true);
    if (!data) {
      return;
    }

    const id = data.split("?id=")[1];

    if (!id) {
      Alert.alert("Ungültiger QR-Code", "Bitte versuchen Sie es erneut.", [
        { text: "OK", onPress: () => setScanned(false) },
      ]);
      return;
    }
    setLoading(true);
    startAR(id);
  };

  if (showAudio !== null) {
    return (
      <AudioScreen
        title={showAudio?.subline || "Wie man früher lebte und arbeitete"}
        subline={showAudio?.title || "Station 6"}
        src={showAudio?.src || ""}
        onExit={() => {
          setShowAudio(null);
        }}
      />
    );
  }

  if (showVideo) {
    return (
      <View className="flex-1 items-center justify-center bg-black">
        <ActivityIndicator color="#fff" />
        <Video
          source={{
            uri: showVideo as string,
          }}
          ref={videoRef}
          className="opacity-0"
          onLoad={() => videoRef.current?.presentFullscreenPlayer()}
          useNativeControls
          onFullscreenUpdate={(e) => {
            if (e.fullscreenUpdate === 3) {
              setShowVideo(false);
              setScanned(false);
              setLoading(false);
            }
          }}
          resizeMode={ResizeMode.CONTAIN}
        />
      </View>
    );
  }

  if (route.params?.id) {
    return (
      <View className="flex-1 items-center justify-center bg-black">
        <ActivityIndicator color="#fff" />
      </View>
    );
  }

  if (!mode) {
    return (
      <View className="flex-1 items-center justify-center bg-main">
        <Text className="text-center text-2xl text-white">
          Wie möchtest du starten?
        </Text>

        <TouchableOpacity
          onPress={() => {
            setMode("audio");
            AsyncStorage.setItem("mode", "audio");
          }}
        >
          <View className="mt-8 w-full rounded-full border border-white p-4">
            <Text className="text-center text-white">Audioguide</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setMode("video");
            AsyncStorage.setItem("mode", "video");
          }}
        >
          <View className="mt-8 w-full rounded-full border border-white p-4">
            <Text className="text-center text-white">Videoguide</Text>
          </View>
        </TouchableOpacity>
        {canUseAr ? (
          <TouchableOpacity
            onPress={() => {
              setMode("ar");
              AsyncStorage.setItem("mode", "ar");
            }}
          >
            <View className="mt-8 w-full rounded-full border border-white p-4">
              <Text className="text-center text-white">Augmented Reality</Text>
            </View>
          </TouchableOpacity>
        ) : null}
      </View>
    );
  }

  return (
    <>
      <View className={`flex-1 ${hasPermission && "bg-black"}`}>
        {hasPermission && isFocused && showCamera ? (
          <BarcodeScannerWrapper
            scan={!scanned}
            loading={loading}
            mode={mode}
            handleBarCodeScanned={handleBarCodeScanned}
          />
        ) : (
          <View className="flex-1 items-center justify-center p-8">
            <Text className="-ml-5 text-center text-6xl">  📷</Text>
            <Text className="text-center text-2xl text-white">
              Zugriff auf Kamera nicht erlaubt.
            </Text>
            <TouchableOpacity
              onPress={() => {
                if (Platform.OS === "ios") {
                  Linking.openURL("app-settings:");
                } else {
                  Linking.openSettings();
                }
              }}
            >
              <View className="mt-8 rounded-full border border-white p-4">
                <Text className="text-center text-white">
                  Einstellungen öffnen
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </>
  );
};

export default ScannerTab;
