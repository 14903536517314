import { BarCodeScanner } from "expo-barcode-scanner";
import React from "react";
import {
  ActivityIndicator,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import ScanIcon from "../assets/scan-outline.svg";
import { SafeAreaView } from "react-native-safe-area-context";
import { Link } from "@react-navigation/native";

const MODE_ALIASES: { [key: string]: string } = {
  ar: "Augmented Reality",
  audio: "Audio",
  video: "Video",
};

const BarcodeScannerWrapper = ({
  handleBarCodeScanned,
  loading,
  mode,
  scan,
}: {
  handleBarCodeScanned: (data: { type: string; data: string }) => void;
  scan: boolean;
  mode: "ar" | "audio" | "video";
  loading: boolean;
}) => {
  const { height } = useWindowDimensions();
  return (
    <>
      <BarCodeScanner
        onBarCodeScanned={scan ? handleBarCodeScanned : undefined}
        style={{
          flex: 1,
          position: "absolute",
          top: 0,
          height: height + 100,
          width: "100%",
        }}
      />
      <View className="absolute left-0 top-0 z-50 w-full items-center">
        <SafeAreaView />
        <Link
          to="/about/settings"
          className="w-full flex-row items-center justify-center "
        >
          <Text className="pr-12 text-center text-white">
            {MODE_ALIASES[mode]}
          </Text>
          <View className="w-[4px]" />
          <Ionicons
            className="pl-4"
            name="settings-outline"
            size={12}
            color="white"
          />
        </Link>
      </View>
      <View className="absolute bottom-5 flex h-screen w-screen items-center justify-center bg-transparent">
        <View className="absolute left-2/3 h-screen w-[0.5] bg-white opacity-30" />
        <View className="absolute left-1/3 h-screen w-[0.5] bg-white opacity-30" />
        <View className="absolute top-2/3 h-[0.5] w-screen bg-white opacity-30" />
        <View className="absolute top-1/3 h-[0.5] w-screen bg-white opacity-30" />
        <Text className="absolute text-lg text-white">
          {loading ? (
            <ActivityIndicator color="white" />
          ) : (
            "Scannen Sie einen der QR-Codes"
          )}
        </Text>
        <ScanIcon
          color={"white"}
          width={220}
          height={220}
          strokeWidth={1}
          style={{
            marginBottom: 0,
            width: 200,
            height: 200,
          }}
        />
      </View>
    </>
  );
};

export default BarcodeScannerWrapper;
