import { registerRootComponent } from "expo";
import "react-native-reanimated";
import "react-native-gesture-handler";
import App from "./App";
import { Text } from "react-native";
// import "expo-router/entry";

Text.defaultProps = {
  ...Text.defaultProps,
  maxFontSizeMultiplier: 1.25,
};
// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
