import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { useNavigation } from "@react-navigation/core";
import { Ionicons, Feather } from "@expo/vector-icons";
import { useSafeAreaInsets } from "react-native-safe-area-context";
function Topbar({
  title,
  floating,
  action,
  dark,
}: {
  title?: string;
  floating?: boolean;
  action?: () => void;
  dark?: boolean;
}) {
  const navigation = useNavigation();
  const { top } = useSafeAreaInsets();
  return (
    <View
      style={{ marginTop: top }}
      className={`top-0 z-50 h-10 w-full flex-row items-center justify-between ${
        floating ? "absolute" : "relative"
      } mb-3`}
    >
      <View className="flex-1 bg-transparent">
        {navigation.canGoBack() ? (
          <TouchableOpacity
            onPress={() => navigation.goBack()}
            className="aspect-square h-16 items-center justify-center bg-transparent"
          >
            <Ionicons
              name="chevron-back-circle-outline"
              size={24}
              color={dark ? "#333" : "white"}
            />
          </TouchableOpacity>
        ) : null}
      </View>
      <View className="flex-grow-1 ml-4 items-center justify-center ">
        <Text className="text-white">{title}</Text>
      </View>
      <View className="flex-grow-1 flex-1 flex-row items-end justify-end pr-4">
        {action ? (
          <TouchableOpacity onPress={action}>
            <Feather name="map" size={20} color="white" />
          </TouchableOpacity>
        ) : null}
        {/* <TouchableOpacity className="mx-2">
          <Sun stroke="white" fill="white" strokeWidth={2} />
        </TouchableOpacity>
        <TouchableOpacity className="mx-2">
          <Settings color="white" strokeWidth={2} />
        </TouchableOpacity> */}
      </View>
    </View>
  );
}

export default Topbar;
