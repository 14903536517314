import React from "react";
import { Image, Text, TouchableOpacity } from "react-native";
import { View } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import Audioplayer from "./Audioplayer";

const AudioScreen = ({
  src,
  title,
  subline,
  onExit,
}: {
  title: string;
  subline: string;
  src: string;
  onExit: () => void;
}) => {
  return (
    <>
      <View
        className="absolute left-4 top-6 z-50
bg-transparent"
      >
        <TouchableOpacity
          onPress={onExit}
          className="items-center justify-center bg-transparent"
        >
          <Ionicons
            name="chevron-back-circle-outline"
            size={24}
            color={"white"}
          />
        </TouchableOpacity>
      </View>

      <View className="relative flex-1 bg-main px-4">
        {/* <ActivityIndicator color="#fff" /> */}
        <Image
          source={require("../assets/hans.png")}
          className="pointer-events-none absolute -bottom-0 -right-20 -z-0 h-2/3 w-full"
          style={{ resizeMode: "contain" }}
        />
        <View className="flex-1 items-center px-8 pt-24">
          <Text
            adjustsFontSizeToFit
            numberOfLines={2}
            className="mb-2 text-center text-3xl font-bold text-white"
          >
            {title}
          </Text>
          <Text
            adjustsFontSizeToFit
            numberOfLines={2}
            className="mb-2 text-center font-light text-white"
          >
            {subline}
          </Text>
          <View className="p-8" />
        </View>
        <View className="mb-12 h-32">
          <Audioplayer
            id="5"
            soundSrc={{
              uri: src,
            }}
          />
        </View>
      </View>
    </>
  );
};

export default AudioScreen;
