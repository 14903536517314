import React, { useEffect } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import HomeLayout from "./home/_layout";
import AboutLayout from "./about/_layout";
import TutorialPage from "./tutorial";
import Splashscreen from "../components/splashscreen";
import { Platform } from "react-native";
import StationPage from "./station";

export type RootStackParamList = {
  home: any;
  about: any;

  map: {
    id: string;
  };
  station: any;
  tutorial: any;
};

const Stack = createNativeStackNavigator<RootStackParamList>();
const RootLayout = () => {
  const [fullyLoaded, setFullyLoaded] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      setFullyLoaded(true);
    }, 4000);
  }, []);

  if (!fullyLoaded && Platform.OS !== "web") {
    return <Splashscreen />;
  }

  return (
    <>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        <Stack.Screen name="home" component={HomeLayout} />
        <Stack.Screen name="about" component={AboutLayout} />
        <Stack.Screen name="station" component={StationPage} />
        <Stack.Screen name="tutorial" component={TutorialPage} />
      </Stack.Navigator>
    </>
  );
};

export default RootLayout;
