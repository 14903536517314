import { useEffect, useRef, useState } from "react";
import { BarCodeScanner } from "expo-barcode-scanner";
import * as ScreenOrientation from "expo-screen-orientation";
import * as Device from "expo-device";
import {
  ActivityIndicator,
  Alert,
  Linking,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import BarcodeScannerWrapper from "../../components/BarcodeScanner";

import { useIsFocused } from "@react-navigation/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { ResizeMode, Video } from "expo-av";
import { startARAndroid } from "../../utils/ar";
import AudioScreen from "../../components/AudioScreen";
const DeviceList = require("../../assets/devices.json");
export const baseUrl =
  process.env.EXPO_PUBLIC_BASE_URL || "https://cms.wald-glas-stuetzerbach.de";

export const apiUrl =
  process.env.EXPO_PUBLIC_API_URL ||
  "https://cms.wald-glas-stuetzerbach.de/api";
export const apiToken =
  process.env.EXPO_PUBLIC_API_TOKEN || "account-b92f17d205bc8317997d5a8db9f17d";

type Mode = "ar" | "audio" | "video";

const ScannerTab = ({ navigation, route }: { navigation: any; route: any }) => {
  const isFocused = useIsFocused();
  const [hasPermission, setHasPermission] = useState<boolean>();
  const [mode, setMode] = useState<Mode>();

  const [scanned, setScanned] = useState(false);
  const [showVideo, setShowVideo] = useState<boolean | string>(false);
  const [canUseAr, setIsARSupportedOnDevice] = useState(false); //
  const [showAudio, setShowAudio] = useState<{
    src: string;
    title: string;
    subline: string;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const [showCamera, setShowCamera] = useState(false);

  const videoRef = useRef<Video>(null);
  useEffect(() => {
    const checkCanUseAr = async () => {
      const { modelName } = Device;
      const canUse = DeviceList.devices.find(
        (deviceName: string) => deviceName === modelName
      );

      if (!canUse) {
        setIsARSupportedOnDevice(false);
        return;
      } else {
        setIsARSupportedOnDevice(true);
      }
    };
    checkCanUseAr();
  }, []);

  useEffect(() => {
    if (route.params?.id) {
      startAR(route.params.id);
    }
  }, [route]);

  useEffect(() => {
    const getBarCodeScannerPermissions = async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === "granted");
    };

    getBarCodeScannerPermissions();
  }, [hasPermission]);

  useEffect(() => {
    if (isFocused) {
      AsyncStorage.getItem("mode").then((mode) => {
        if (mode) {
          setMode(mode as Mode);
        }
      });

      setLoading(false);
      setShowVideo(false);
      setShowAudio(null);
      setScanned(false);
      setShowCamera(true);
      ScreenOrientation.lockAsync(
        ScreenOrientation.OrientationLock.PORTRAIT_UP
      );
    } else {
      navigation.setParams({ tabBar: true });
    }
  }, [isFocused]);

  useEffect(() => {
    if (navigation) {
      if (showAudio) {
        navigation.setParams({ tabBar: false });
      } else {
        setShowCamera(false);
        setShowCamera(true);
        setScanned(false);
        setLoading(false);
        navigation.setParams({ id: null, tabBar: true });
      }
    }
  }, [showAudio, navigation]);
  // useEffect(() => {
  //   if (!showVideo) {
  //     ScreenOrientation.lockAsync(
  //       ScreenOrientation.OrientationLock.PORTRAIT_UP
  //     );
  //   } else {
  //     ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE);
  //   }
  // }, [showVideo]);

  const startAR = async (id: string) => {
    const isMundart = await AsyncStorage.getItem("isMundart");
    const url = `${apiUrl}/collections/get/stationen/?filter[title_slug]=${id}&token=${apiToken}&simple=1&date=${Date.now()}`;
    const data = await fetch(url).then((res) => res.json());

    if (!data) {
      Alert.alert("Ungültiger QR-Code", "Bitte versuchen Sie es erneut.", [
        { text: "OK", onPress: () => setScanned(false) },
      ]);
      return;
    }

    if (data[0].video) {
      setShowVideo(true);
      return;
    }

    if (mode === "video") {
      const video =
        isMundart === "true"
          ? `${baseUrl}/${data[0].arvideo_mundart}`
          : `${baseUrl}/${data[0].arvideo}`;
      setShowVideo(video as string);
      return;
    }

    if (!canUseAr || mode === "audio") {
      const sound =
        isMundart === "true"
          ? `${baseUrl}/${data[0].android.audio_mundart}`
          : `${baseUrl}/${data[0].android.audio}`;
      setShowAudio({
        src: sound,
        title: data[0].title,
        subline: data[0].subline,
      });
      return;
    }

    const src =
      isMundart === "true"
        ? `${baseUrl}/${data[0].android.model_mundart}`
        : `${baseUrl}/${data[0].android.model}`;
    const sound =
      isMundart === "true"
        ? `${baseUrl}/${data[0].android.audio_mundart}`
        : `${baseUrl}/${data[0].android.audio}`;

    await startARAndroid({ src, sound });

    setTimeout(() => {
      setShowCamera(false);
      setShowCamera(true);
      setScanned(false);
      setLoading(false);
      navigation.setParams({ id: null });
    }, 2000);
    return;
  };

  const handleBarCodeScanned = ({ data }: { type: string; data: string }) => {
    setScanned(true);
    if (!data) {
      return;
    }

    const id = data.split("?id=")[1];

    if (!id) {
      return Alert.alert("Ungültiger QR-Code", "Bitte versuche es erneut.", [
        { text: "OK", onPress: () => setScanned(false) },
      ]);
    }
    setLoading(true);
    startAR(id);
  };

  if (!mode) {
    return (
      <View className="flex-1 items-center justify-center bg-main">
        <Text className="text-center text-2xl text-white">
          Wie möchtest du starten?
        </Text>

        <TouchableOpacity
          onPress={() => {
            setMode("audio");
            AsyncStorage.setItem("mode", "audio");
          }}
        >
          <View className="mt-8 w-full rounded-full border border-white p-4">
            <Text className="text-center text-white">Audioguide</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setMode("video");
            AsyncStorage.setItem("mode", "video");
          }}
        >
          <View className="mt-8 w-full rounded-full border border-white p-4">
            <Text className="text-center text-white">Videoguide</Text>
          </View>
        </TouchableOpacity>
        {canUseAr ? (
          <TouchableOpacity
            onPress={() => {
              setMode("ar");
              AsyncStorage.setItem("mode", "ar");
            }}
          >
            <View className="mt-8 w-full rounded-full border border-white p-4">
              <Text className="text-center text-white">Augmented Reality</Text>
            </View>
          </TouchableOpacity>
        ) : null}
      </View>
    );
  }

  if (showAudio !== null) {
    return (
      <AudioScreen
        title={showAudio?.subline || "Wie man früher lebte und arbeitete"}
        subline={showAudio?.title || "Station 6"}
        src={showAudio?.src || ""}
        onExit={() => {
          setShowAudio(null);
        }}
      />
    );
  }
  if (showVideo) {
    return (
      <View className="flex-1 items-center justify-center bg-black">
        <ActivityIndicator color="#fff" />
        <Video
          source={{
            uri: showVideo as string,
          }}
          ref={videoRef}
          className="opacity-0"
          onLoad={() => videoRef.current?.presentFullscreenPlayer()}
          useNativeControls
          onFullscreenUpdate={(e) => {
            if (e.fullscreenUpdate === 3) {
              setShowVideo(false);
              setScanned(false);
              setLoading(false);
            }
          }}
          resizeMode={ResizeMode.CONTAIN}
        />
      </View>
    );
  }
  if (route.params?.id) {
    return (
      <View className="flex-1 items-center justify-center bg-black">
        <ActivityIndicator color="#fff" />
      </View>
    );
  }
  return (
    <>
      <View className={`flex-1 ${hasPermission && "bg-black"}`}>
        {hasPermission && isFocused && showCamera ? (
          <BarcodeScannerWrapper
            scan={!scanned}
            loading={loading}
            mode={mode}
            handleBarCodeScanned={handleBarCodeScanned}
          />
        ) : (
          <View className="flex-1 items-center justify-center p-8">
            <Text className="-ml-5 text-center text-6xl">  📷</Text>
            <Text className="text-center text-2xl text-white">
              Zugriff auf Kamera nicht erlaubt.
            </Text>
            <TouchableOpacity
              onPress={() => {
                if (Platform.OS === "ios") {
                  Linking.openURL("app-settings:");
                } else {
                  Linking.openSettings();
                }
              }}
            >
              <View className="mt-8 rounded-full border border-white p-4">
                <Text className="text-center text-white">
                  Einstellungen öffnen
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </>
  );
};

export default ScannerTab;
