import React, { useEffect, useState } from "react";

import {
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
  ScrollView,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Ionicons } from "@expo/vector-icons";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "./_layout";
import { apiToken, apiUrl, baseUrl } from "./home/scanner.ios";
import { useQuery } from "react-query";
import Background from "../components/Background";
import colors from "../tailwind/colors";
import { startARAndroid } from "../utils/ar";
import { getMobileOperatingSystem } from "../utils/getOperatingSystem";
import * as Device from "expo-device";
import AudioScreen from "../components/AudioScreen";
import { ResizeMode, Video } from "expo-av";

const DeviceList = require("../assets/devices.json");
console.log(DeviceList);
type Props = NativeStackScreenProps<RootStackParamList, "station">;

const fetchStation = async (id: string) => {
  const url = `${apiUrl}/collections/get/stationen/?filter[title_slug]=${id}&token=${apiToken}&simple=1`;
  const data = await fetch(url).then((res) => res.json());

  return data[0];
};

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "model-viewer": any;
    }
  }
}
type Mode = "ar" | "audio" | "video";
export default function StationPage({ route, navigation }: Props) {
  const [isMundart, setIsMundart] = useState(false);
  const [canUseAR, setCanUseAR] = useState(false);
  const [mode, setMode] = useState<Mode>();
  if (!route.params?.id) return null;

  const { data: page, isLoading } = useQuery(`station-${route.params.id}`, () =>
    fetchStation(route.params?.id)
  );

  const isAndroid = getMobileOperatingSystem() === "Android";

  const startAR = async () => {
    startARAndroid({
      src: `${baseUrl}/${page?.android.model}`,
      sound: `${baseUrl}/${page?.android.audio}`,
    });
  };

  useEffect(() => {
    const getSavedStatus = async () => {
      const isMundart = await AsyncStorage.getItem("isMundart");
      if (isMundart === "true") {
        setIsMundart(true);
      } else {
        setIsMundart(false);
      }
    };
    getSavedStatus();
  }, []);

  useEffect(() => {
    if (document) {
      const { modelName } = Device;
      const canUse = DeviceList.devices.find(
        (deviceName: string) => deviceName === modelName
      );

      setCanUseAR(canUse !== undefined || Device.osName === "iOS");
    }
  }, [document, page, navigator]);

  if (isLoading) {
    return (
      <View className="flex-1 justify-center">
        <Background colors={[colors.main[500], colors.main[400]]} />
        <ActivityIndicator color="white" size="large" />
      </View>
    );
  }

  if (!mode) {
    return (
      <View className="flex-1 items-center justify-center bg-main">
        <Text className="mb-8 text-center text-2xl text-white">
          Wie möchtest du starten?
        </Text>
        <View className="my-4">
          <TouchableOpacity
            className="flex flex-row items-center gap-3"
            onPress={() => setIsMundart(!isMundart)}
          >
            <View
              className={`aspect-square h-8 w-8 rounded-full ${
                isMundart ? "bg-white" : "border border-white bg-transparent"
              }`}
            />
            <Text className="text-white">Mundart aktivieren</Text>
          </TouchableOpacity>
        </View>
        <View className="h-[1] w-2/3 bg-white opacity-25" />
        <TouchableOpacity
          onPress={() => {
            setMode("audio");
            AsyncStorage.setItem("mode", "audio");
          }}
        >
          <View className="mt-8 w-full rounded-full border border-white p-4">
            <Text className="text-center text-white">Audioguide</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setMode("video");
            AsyncStorage.setItem("mode", "video");
          }}
        >
          <View className="mt-8 w-full rounded-full border border-white p-4">
            <Text className="text-center text-white">Videoguide</Text>
          </View>
        </TouchableOpacity>
        {canUseAR ? (
          <TouchableOpacity
            onPress={() => {
              setMode("ar");
              AsyncStorage.setItem("mode", "ar");
            }}
          >
            <View className="mt-8 w-full rounded-full border border-white p-4">
              <Text className="text-center text-white">Augmented Reality</Text>
            </View>
          </TouchableOpacity>
        ) : null}
      </View>
    );
  }

  if (mode === "video") {
    const video = isMundart
      ? `${baseUrl}/${page.arvideo_mundart}`
      : `${baseUrl}/${page.arvideo}`;

    return (
      <View className="relative flex-1 bg-orange-200">
        <View
          className="absolute left-0 top-8 z-50 h-10 flex-1
bg-transparent"
        >
          <TouchableOpacity
            onPress={() => navigation.goBack()}
            className="aspect-square h-16 items-center justify-center bg-transparent"
          >
            <Ionicons
              name="chevron-back-circle-outline"
              size={24}
              color={"white"}
            />
          </TouchableOpacity>
        </View>

        <Video
          source={{
            uri: video as string,
          }}
          useNativeControls
          shouldPlay
          style={{
            height: "100vh",
            width: "100%",
          }}
          videoStyle={{
            objectFit: "cover",
            height: "100%",
            width: "100%",
          }}
        />
      </View>
    );
  }
  if (mode === "audio" || !canUseAR) {
    const sound = isMundart
      ? `${baseUrl}/${page.android.audio_mundart}`
      : `${baseUrl}/${page.android.audio}`;
    return (
      <AudioScreen
        src={sound}
        title={page?.title}
        subline={page?.subline}
        onExit={() => navigation.goBack()}
      />
    );
  }

  return (
    <View className="flex-1">
      <Background colors={[colors.main[500], colors.main[400]]} />
      <View className="mb-3 h-10" />

      <ScrollView endFillColor="#000" overScrollMode="never">
        <View className="mt-5 flex w-full flex-col px-6">
          <View className="flex-col">
            <Text
              adjustsFontSizeToFit
              numberOfLines={2}
              className="mb-2 text-3xl text-white"
            >
              {page.title}
            </Text>
            <Text className="mb-4 text-lg text-white">{page.subline}</Text>
          </View>
          <View className="mt-4">
            <TouchableOpacity
              className="flex flex-row items-center gap-3"
              onPress={() => setIsMundart(!isMundart)}
            >
              <View
                className={`aspect-square h-8 w-8 rounded-full ${
                  isMundart ? "bg-white" : "border border-white bg-transparent"
                }`}
              />
              <Text className="text-white">Mundart aktivieren</Text>
            </TouchableOpacity>
          </View>
        </View>
        <View className="h-12" />
        <View className="items-center justify-center">
          <model-viewer
            src={`${baseUrl}/${page?.android.model}`}
            ios-src={`${baseUrl}/${page?.ios.model}`}
            ar
            ar-modes="quick-look scene-viewer"
            touch-action="pan-y"
            style={{
              height: "60vh",
            }}
            id="model-viewer"
          >
            <button
              className={`absolute bottom-0 left-0 right-0 mx-auto self-center rounded-full bg-accent px-12 py-4 ${
                isAndroid && "hidden! !hidden opacity-0"
              }`}
              slot="ar-button"
            >
              <Text className="font-semibold text-white">AR Starten</Text>
            </button>
          </model-viewer>

          {canUseAR && isAndroid && (
            <TouchableOpacity
              className="absolute z-50 self-center rounded-full bg-accent px-12 py-4"
              onPress={startAR}
            >
              <Text className="font-semibold text-white">AR Starten</Text>
            </TouchableOpacity>
          )}
        </View>

        <View className="h-24" />
      </ScrollView>
    </View>
  );
}
