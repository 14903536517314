import * as Linking from "expo-linking";
export async function startARIOS({ src }: { src: string }) {
  return await Linking.openURL(src);
}

export async function startARAndroid({
  sound,
  src,
}: {
  sound?: string;
  src: string;
}) {
  if (sound) {
    return await Linking.openURL(
      `https://arvr.google.com/scene-viewer/1.1?file=${src}&mode=ar_only&resizable=false&sound=${sound}#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end`
    );
  }
  return await Linking.openURL(
    `https://arvr.google.com/scene-viewer/1.1?file=${src}&mode=ar_preferred#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;endr&sound=${sound}`
  );
}
