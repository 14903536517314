import React from "react";
import RenderHtml, { MixedStyleDeclaration } from "react-native-render-html";

const htmlStyles: Readonly<Record<string, MixedStyleDeclaration>> = {
  a: {
    textDecorationLine: "none",
    fontSize: 16,
    borderWidth: 0,
  },
  p: {
    marginTop: 0,
    fontSize: 16,
    lineHeight: 16 * 1.7,
    color: "white",
  },
  ul: {
    color: "white",
    paddingLeft: 10,
    margin: 0,
  },

  li: {
    marginTop: -4,
    fontSize: 14,
    lineHeight: 14 * 1.7,
    color: "#fff",
    marginBottom: 12,
    marginLeft: 4,
  },
};

const Html = (props: { html: string }) => {
  return (
    <RenderHtml
      defaultTextProps={{
        allowFontScaling: false,
      }}
      baseStyle={{ maxWidth: "100%" }}
      contentWidth={0}
      classesStyles={{
        "no-style": {
          paddingLeft: 0,
          listStyleType: "none",
        },
      }}
      tagsStyles={htmlStyles}
      source={{ html: props.html }}
    />
  );
};

export default Html;
