import { MotiPressable } from "moti/interactions";
import { useMemo } from "react";
import { View, Text } from "react-native";
import colors from "../tailwind/colors";

export default function MyTabBar({
  state,
  descriptors,
  navigation,
}: {
  state: any;
  descriptors: any;
  navigation: any;
}) {
  const hide = state.routes.find((route: any, index: number) => {
    return route.params?.tabBar === false;
  });
  if (hide) {
    return null;
  }
  return (
    <View
      style={{
        position: "absolute",
        backgroundColor: `${colors.main[600]}`,
        justifyContent: "space-between",
        alignItems: "center",
        paddingHorizontal: 24,
        paddingBottom: 10,
        paddingTop: 10,
        flex: 1,
        bottom: 20,
        left: 16,
        right: 16,
        borderRadius: 40,
        flexDirection: "row",
      }}
    >
      {state.routes.map((route: any, index: number) => {
        const { options } = descriptors[route.key];
        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;

        const icon = options.tabBarIcon;
        const isFocused = state.index === index;
        const onPress = () => {
          const event = navigation.emit({
            type: "tabPress",
            target: route.key,
            canPreventDefault: true,
          });

          if (!isFocused && !event.defaultPrevented) {
            // The `merge: true` option makes sure that the params inside the tab screen are preserved
            navigation.navigate({ name: route.name, merge: true });
          }
        };

        return (
          <MotiPressable
            key={index}
            onPress={onPress}
            style={{
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              paddingHorizontal: 8,
            }}
            animate={useMemo(
              () =>
                ({ pressed }) => {
                  "worklet";
                  return {
                    scale: pressed ? 0.85 : 1,
                  };
                },
              []
            )}
          >
            {icon && icon(isFocused)}
            {typeof label === "string" ? (
              <Text
                className=" mt-1 text-xs"
                style={{ color: isFocused ? "#fff" : "#fffa" }}
              >
                {label}
              </Text>
            ) : null}
          </MotiPressable>
        );
      })}
    </View>
  );
}
